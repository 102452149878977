#legal-matters a {
  padding: 0;
}

#legal-matters > div:nth-child(2) > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#legal-matters > div:nth-child(2) > header > h1 {
  font-family: 'Elephant Pro';
  font-size: 5rem;
}
