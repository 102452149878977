@import '../../../../styles/customMediaQueries.css';

#privacy-policy-block-1,
#interpretation-and-definitions,
#contact-us,
#collecting-and-using-your-personal-data,
#detailed-information-on-the-processing-of-your-personal-data,
#links-to-other-websites,
#gdpr-privacy,
#california-privacy-rights,
#childrens-and-minor-users-privacy-rights {
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }
}
